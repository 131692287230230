import React from 'react';

export default class YoutubePlayer extends React.Component {
  constructor(props) {
    super();
    this.videoId = props.videoId;
  }

  render() {
    return (
      <a href={`https://www.youtube.com/watch/${this.videoId}`} className="popup-youtube" id={this.videoId} title="Videó">
        <img className="card" src={`https://i.ytimg.com/vi/${this.videoId}/hqdefault.jpg`} alt="Videó"></img>
        <span className="play-button">
          <span></span>
        </span>
      </a>
    );
  }
}
