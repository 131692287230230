import React from 'react';
import YoutubePlayer from './youtube-player';

export default class Possibilities extends React.Component {
  render() {
    return (
      <div className="row" id="fh5co-features">
        <div className="col-md-4 col-sm-6 text-center fh5co-feature animate-transparent feature-box">
          <div className="fh5co-feature-icon">
            <i className="ti-home"></i>
          </div>
          <h3 className="heading">Otthon</h3>
          <p>A nagy méretű felszerelt konyha, az étkezőrész és a tágas, konyhával egybeúsztatott , KLíMÁS nappali KIVÁLÓ ÉLETTERET nyújthat családoknak és remek helyszínt adhat családi, baráti összejöveteleknek.</p>
        </div>
        <div className="col-md-4 col-sm-6 text-center fh5co-feature animate-transparent feature-box">
          <div className="fh5co-feature-icon">
            <i className="ti-desktop"></i>
          </div>
          <h3 className="heading">Iroda</h3>
          <p>A szerencsés új tulajdonos megtalálja megkapja itt a BELVÁROS MINDEN ELŐNYÉT, ÖTVÖZVE A TERMÉSZET LÁGY ÖLELÉSÉVEL. A LAKÁSTÓL pár perc sétára van a DUNAPART, de a SZENT ISTVÁN PARK ÉS MARGIT-SZIGET IS NÉHÁNY PERC ALATT MEGKÖZELíTHETŐ.
          </p>
        </div>
        <div className="clearfix visible-sm-block"></div>
        <div className="col-md-4 col-md-offset-0 col-sm-6 col-sm-offset-3 text-center fh5co-feature animate-transparent feature-box">
          <div className="fh5co-feature-icon">
            <i className="ti-stats-up"></i>
          </div>
          <h3 className="heading">Befektetés</h3>
          <p>A környéken számos lehetőség kínálkozik a bevásárlásra (Lehel piac, Westend, stb.), valamint számos óvoda, iskola, étterem színesíti a remek infrastruktúrát. </p>
        </div>
        <div className="clearfix visible-md-block visible-lg-block"></div>
        <div className="fh5co-feature animate-transparent container-video feature-box col-xs-12 col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3 clearfix text-center">
          <YoutubePlayer videoId="xqwxGlzqTcU" />
        </div>
      </div>
    );
  }
}
