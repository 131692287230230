import firebase from 'firebase';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyBgl8uonkTB-uup6lMWzWdxJ93h-peikSI',
  authDomain: 'ivan-garden.firebaseapp.com',
  projectId: 'ivan-garden',
  storageBucket: 'ivan-garden.appspot.com',
  messagingSenderId: '965497140566',
  appId: '1:965497140566:web:9444f9363a74b8446b12f7',
  measurementId: 'G-PSKH9X89EX'
};

export default class Analytics {
  static init() {
    if (typeof window !== 'undefined' && !firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      if ('measurementId' in firebaseConfig) {
        firebase.analytics();
      }
    }
  }
}
