import React from 'react';

export default class Description extends React.Component {
  render() {
    return (
      <div id="container-description" className="fh5co-feature animate-transparent feature-box">
        <p>
          Újlipótvárosi álom: belső kétszintes, duplakomfortos, tágas otthon szép kilátással Újlipótváros közepén, ingyenes udvari parkolási lehetőséggel!
        </p>
        <p>
          A 13. kerület szívében, ÚJLIPÓTVÁROSBAN, a Visegrádi utcában egy 92 nm-es, BELSŐ KÉTSZINTES, DUPLAKOMFORTOS, nagy terekkel rendelkező, jó állapotú lakás eladó. A lakás kelet-nyugati tájolású, egész nap fényárban úszik. A rendkívül otthonos ingatlan kifejezetten kellemes hangulatot áraszt. Az ingatlan TEHERMENTES.
        </p>
        <p>
          <strong>A társasház</strong>
        </p>
        <p>
          A ház 80-as évek végén épült, állandóan karbantartott, 2 LIFTES társasház. A 92 nm-es lakás a  VIII. emeleten található.   A lakásban a cirkófűtésről és a melegvíz ellátásról házközponti kazán gondoskodik.
          Az épület nagyon jó állapotú, a LIFT frissen lett felújítva.
        </p>
        <p>
          <strong>Amerikai konyhás nappali három hálószobával</strong>
        </p>
        <p>
          A lakásba lépve az előtérbe jutunk, amin keresztül a hangulatos AMERIKAI KONYHÁS NAPPALIBA léphetünk be. A nagy méretű felszerelt konyha, az étkezőrész és a tágas, konyhával egybeúsztatott, KLíMÁS nappali KIVÁLÓ ÉLETTERET nyújthat családoknak és remek helyszínt adhat családi, baráti összejöveteleknek. A konyhában és a vizes helyiségekben csempe és kőburkolat van. Az alsó szinten található egy zuhanyzós fürdőszoba, amiből a KLíMÁS alsó szinti hálószobába juthatunk.
        </p>
        <p>
          A szobákban parketta és laminált padló került lerakásra. A lakás burkolatai, berendezései, tartozékai FIATALOSAK ÉS OTTHONOSAK. A nappaliból egy jó állapotú és stabil kialakítású lépcsőn a galériára érünk.A nappali felé nyitott galéria tágassága lehetőséget adhat akár egy dolgozósarok kialakítására is. Innen nyílik egy tágas hálószoba, amely jelenleg gyerekszobaként funkcionál. Ebből a szobából kiváló PANORÁMA NYíLIK A JÁNOS-HEGYRE ÉS A SZÉCHÉNYI-HEGYRE. A galáriából az előtéren keresztül közelíthetjük meg az emeleti KÁDAS FÜRDŐSZOBÁT és a másik emeleti hálószobát, amihez FRANCIA ERKÉLY is tartozik. Ebből a hálóból a BELVÁROS JELLEGZETES TORNYAIRA (pl.: Bazilika, Nemzeti Galéria, Budai Vár), valamint a GELLÉRTHEGYRE NYíLIK KILÁTÁS. Több helyiségben NAGYOBB MÉRETŰ BEÉPíTETT GARDRÓBOK gazdagítják a szobák tárolási lehetőséget, megőrizve a lakás tágas élettereit.
        </p>
        <p>
          <strong>Elhelyezkedés</strong>
        </p>
        <p>
          A szerencsés új tulajdonos megtalálja megkapja itt a BELVÁROS MINDEN ELŐNYÉT, ÖTVÖZVE A TERMÉSZET LÁGY ÖLELÉSÉVEL. A LAKÁSTÓL pár perc sétára van a DUNAPART, de a SZENT ISTVÁN PARK ÉS MARGIT-SZIGET IS NÉHÁNY PERC ALATT MEGKÖZELíTHETŐ.
          A lakás előtt 2000 Ft/éves parkolási díjért lehet parkolni kerületi parkolómatricával. A lakás közösségi közlekedéssel is kiválóan megközelíthető. Emellett számos lehetőség kínálkozik a bevásárlásra kisboltok formájában, valamint számos óvoda, iskola, étterem színesíti a remek infrastruktúrát.
        </p>
        <p>
          <strong>EXTRÁK</strong>
        </p>
        <p>
          Újlipótvárosban szinte egyedülálló módon egy óriási saját kert tartozik a társasházhoz. A belső udvar egy részében kifejezetten a ház lakói számára DíJMENTESEN HASZNÁLHATÓ PARKOLÓHELYEKET alakítottak ki, amit a lakók parkolókártyával használhatnak.
        </p>
        <p>
          Az udvar többi részén egy SAJÁT JÁTSZÓTERET alakítottak ki. A játszóteret és a gépkocsibeállót egy gyönyörűen karbantartott, virágokkal szépen beültetett magaságyásokkal GAZDAGíTOTT KERT veszi körbe.
          -A házban lévő egy meghatározott TÁROLÓHELYISÉGET a lakás tulajdonosa használhat.
        </p>
        <p>
          A lakás előnye, hogy a folyosón kialakított belső kertre néző erkélyről fogyaszthatjuk el a reggelinket, vagy a délutáni kávét.
          A lakást kifejezetten ajánlom pároknak és családoknak, mivel a lakás elosztása kifogástalan, hangulata kifejezetten OTTHONOS, de irodának vagy orvosi rendelőnek is alkalmas lehet.
        </p>
        <p>
          <strong>Az ár</strong>
        </p>
        <p>
          A lakás ára 81,9 millió Ft.<br />
          A közös költségen felül a villanynak és az internetnek van havi költsége.
        </p>
      </div>
    );
  }
}