import ie from 'ie-version';

export default class Scripts {
  static addScripts = () => {
    const scripts = [
      (ie.version && ie.version < 9) ? 'respond.min.js' : 'modernizr-2.6.2.min.js',
      'jquery-1.10.2.min.js',
      'jquery.easing.1.3.js',
      'owl.carousel.min.js',
      'jquery.magnific-popup.min.js',
      'hoverIntent.js',
      'superfish.js',
      'easyResponsiveTabs.js',
      'fastclick.js',
      'jquery.waypoints.min.js',
      'main.js',
      'form.js'
    ];

    scripts.forEach(Scripts.#importScript);
  };

  static #importScript = resourceName => {
    const resourceUrl = `./js/${resourceName}`;
    const script = document.createElement('script');

    script.src = resourceUrl;
    script.async = false;
    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  };
}
