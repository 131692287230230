import React from 'react';
import logo from '../images/grass.png';

export default class Header extends React.Component {
  render() {
    return (
      <header id="fh5co-header-section" role="heading">
        <div className="container">
            <h1 id="fh5co-logo" className="pull-left">
              <a href="/">
                <img src={logo} alt="Ivan Garden" />
              </a>
            </h1>
            <nav id="fh5co-menu-wrap" role="navigation">
              <ul className="sf-menu menu-transparent" id="fh5co-primary-menu">
                <li className="smoothscroll">
                  <a className="smoothscroll" href="#fh5co-main">Lehetőségek</a>
                </li>
                <li>
                  <a className="smoothscroll" href="#fh5co-info">Adatok</a>
                </li>
                <li>
                  <a className="smoothscroll" href="#fh5co-gallery">Galéria</a>
                </li>
                <li>
                  <a className="smoothscroll" href="#fh5co-footer">Kapcsolat</a>
                </li>
              </ul>
            </nav>
        </div>
      </header>
    );
  }
}
