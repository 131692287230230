import React from 'react';
import Header from './components/header';
import Footer from './components/footer';
import Gallery from './components/gallery';
import Slideshow from './components/slideshow';
import Possibilities from './components/possibilities';
import Specs from './components/specs';
import Description from './components/description';

import Scripts from './utils/scripts';
import Analytics from './utils/analytics';

import './css/themify-icons.css';
import './css/bootstrap.css';
import './css/owl.theme.default.min.css';
import './css/magnific-popup.css';
import './css/superfish.css';
import './css/easy-responsive-tabs.css';
import './css/animate.css';

import './sass/style.scss';
import coverImage from './images/gallery/1.jpg';

function App() {
  React.useEffect(() => {
    Analytics.init();
    Scripts.addScripts();
  }, []);

  return (
    <div className="App">
      <Header />
      <Slideshow />
      <div id="fh5co-main">
        <div className="container">
          <Possibilities />
          <hr className="animate-transparent animate-box" />
          <div className="fh5co-spacer fh5co-spacer-md"></div>
          <Specs />
          <Description />
          <hr className="animate-transparent animate-box" />
          <div id="fh5co-gallery" className="row info-container info-margin-top animate-transparent animate-box">
            <div className="col-xs-12 col-sm-6 col-sm-offset-1">
              <h2 className="info-title">Galéria</h2>
              <ul>
                <li>A geléria megtekintéséhez kattintson a képre</li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 info-img-container">
              <a id="btn-gallery" href="#gallery-product-header" title="Galéria">
                <img className="card img-card card-btn-gallery" src={coverImage} alt="Galéria" />
              </a>
              <Gallery />
            </div>
          </div>
          <p id="more-info" className="card animate-transparent animate-box">
            További információ az <a rel="noopener noreferrer" href="https://ingatlan.com/31664349" target="_blank">ingatlan.com/31664349</a> oldalon található.
          </p>
          <div className="fh5co-spacer fh5co-spacer-md"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
