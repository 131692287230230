import React from 'react';
import slideshow from '../sass/_slideshow.scss';

export default class Slideshow extends React.Component {
  constructor(props) {
    super(props);
    const count = parseInt(slideshow.itemCount);

    this.images = [...Array(count)].map((_element, index) => (
      <div key={index} className={`slideshow-image img-${index + 1}`}></div>
    ));
  }

  render() {
    return (
      <div id="fh5co-hero">
        <a href="#fh5co-main" className="smoothscroll fh5co-arrow to-animate animate-transparent hero-animate-4">
          <i className="ti-angle-down"></i>
        </a>
        <div className="col-md-8 col-md-offset-2">
          <div className="fh5co-hero-wrap">
            <div className="fh5co-hero-intro">
              <h1 className="to-animate animate-transparent hero-animate-1">Ivan Garden</h1>
              <h2 className="to-animate animate-transparent hero-animate-2">Természetességet a lakásba</h2>
            </div>
          </div>
        </div>
        <div className="header-container">
          <div className="slideshow">{this.images}</div>
        </div>
      </div>
    );
  }
}
