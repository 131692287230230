import React from 'react';

export default class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.images = this.importAll(require.context(`${__dirname}/../images/gallery`, false, /\.(gif|jpe?g|svg)$/));
  }

  importAll(r) {
    return r.keys().map((item, index) => (
      <a key={index} href={r(item)} alt="Galéria">&nbsp;</a>
    ));
  }

  render() {
    return (
      <div id="gallery-product-header" className="gallery-image-links">{this.images}</div>
    );
  }
}
