import React from 'react';
import agent from '../images/p_gyimesi.jpg';

export default class Footer extends React.Component {
  render() {
    return (
      <footer role="contentinfo" id="fh5co-footer">
        <a href="/#" className="fh5co-arrow fh5co-gotop animate-transparent footer-box">
          <i className="ti-angle-up"></i>
        </a>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-4 animate-transparent footer-box footer-center">
              <img className="card" src={agent} alt="Gyimesi Péter"></img>
              <div id="container-agent-info">
                <div>Gyimesi Péter - Ingatlanközvetítő</div>
                <div><a href="tel:+36703310996">+36 70 331 0996</a></div>
              </div>
            </div>
            <div className="footer-box animate-transparent col-xs-12 col-sm-8">
              <h3 className="fh5co-footer-heading">Kérdése van?</h3>
              <h1 className="to-animate hero-animate-1">Írjon nekünk!</h1>

              <form className="footer-box footer-form" action="" method="post">
                <div className="form-group">
                  <label htmlFor="name" className="sr-only">Név</label>
                  <input placeholder="Név" id="name" type="text" className="form-control input-lg" required />
                </div>
                <div className="form-group">
                  <label htmlFor="email" className="sr-only">Email</label>
                  <input placeholder="Email" id="email" type="email" className="form-control input-lg" required />
                  <input placeholder="Confirm Email" id="confirm-email" type="email" className="form-control input-lg" tabIndex="-1" autoComplete="off" />
                </div>
                <div className="form-group">
                  <label htmlFor="message" className="sr-only">Üzenet</label>
                  <textarea placeholder="Üzenet" id="message" className="form-control input-lg" rows="3"></textarea>
                </div>
                <div className="form-group">
                  <input type="submit" className="btn btn-primary btn-lg" value="Küldés" />
                </div>
              </form>
            </div>
          </div>
          <div className="fh5co-spacer fh5co-spacer-md"></div>
        </div>
      </footer>
    );
  }
}
