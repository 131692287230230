import React from 'react';

export default class Specs extends React.Component {
  render() {
    return (
      <div id="fh5co-info" className="row info-container animate-transparent animate-box">
        <div className="col-xs-12 col-sm-5 info-img-container container-ruler">
          <i className="ti-ruler-pencil"></i>
        </div>
        <div className="col-xs-12 col-sm-7">
          <h2 className="info-title">Adatok</h2>
          <div className="paramterers">
            <div className="row">
              <div className="col-xs-6"><strong>Ingatlan állapota</strong></div>
              <div className="col-xs-6 info-align-right">Jó állapotú</div>
            </div>
            <div className="row">
              <div className="col-xs-6"><strong>Építés éve</strong></div>
              <div className="col-xs-6 info-align-right">1981-2000 között</div>
            </div>
            <div className="row">
              <div className="col-xs-6"><strong>Komfort</strong></div>
              <div className="col-xs-6 info-align-right">Duplakomfortos</div>
            </div>
            <div className="row">
              <div className="col-xs-6"><strong>Emelet</strong></div>
              <div className="col-xs-6 info-align-right">8</div>
            </div>
            <div className="row">
              <div className="col-xs-6"><strong>Épület szintjei</strong></div>
              <div className="col-xs-6 info-align-right">9</div>
            </div>
            <div className="row">
              <div className="col-xs-6"><strong>Lift</strong></div>
              <div className="col-xs-6 info-align-right">Van</div>
            </div>
            <div className="row">
              <div className="col-xs-6"><strong>Belmagasság</strong></div>
              <div className="col-xs-6 info-align-right">3 m-nél alacsonyabb</div>
            </div>
            <div className="row">
              <div className="col-xs-6"><strong>Fűtés</strong></div>
              <div className="col-xs-6 info-align-right">Házközponti</div>
            </div>
            <div className="row">
              <div className="col-xs-6"><strong>Légkondicionáló</strong></div>
              <div className="col-xs-6 info-align-right">Van</div>
            </div>
            <div className="row">
              <div className="col-xs-6"><strong>Fürdő és WC</strong></div>
              <div className="col-xs-6 info-align-right">Egy helyiségben</div>
            </div>
            <div className="row">
              <div className="col-xs-6"><strong>Tájolás</strong></div>
              <div className="col-xs-6 info-align-right">Kelet, nyugat</div>
            </div>
            <div className="row">
              <div className="col-xs-6"><strong>Kilátás</strong></div>
              <div className="col-xs-6 info-align-right">Panorámás</div>
            </div>
            <div className="row">
              <div className="col-xs-6"><strong>Kertkapcsolatos</strong></div>
              <div className="col-xs-6 info-align-right">Igen</div>
            </div>
            <div className="row">
              <div className="col-xs-6"><strong>Parkolás</strong></div>
              <div className="col-xs-6 info-align-right">Udvari beálló - benne van az árban</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
